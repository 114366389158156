.modal-content {
  background-color: #f5f5f5 !important;
  border: 4px solid #4c7235 !important;
  border-radius: 10px !important;
  color: #4c7235 !important;
  padding-bottom: 3% !important;
}

.button-close-global {
  margin: 0 !important;
  top: -20px !important;
  right: -20px !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 23px !important;
  background-color: #d1d93e !important;
  color: #4c7235 !important;
  background-image: url('./assets/images/close.png') !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  z-index: 10;
}

.button-close-global:hover {
  background-color: #4c7235 !important;
  color: #fff !important;
  background-image: url('./assets/images/close.png') !important;
}

.button-next {
  width: 180px;
  height: 50px;
  background-color: #e94e1e !important;
  color: #f5f5f5 !important;
  border: 2px solid transparent !important;
  font-size: 20px !important;
  font-family: 'Banda Regular' !important;
  font-weight: bold !important;
  margin-left: 5% !important;
}

.button-next:hover {
  background-image: none !important;
}

.scroll-habeas-data {
  height: 600px;
  overflow-x: scroll;
}

//Etiquetas
p {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: unset !important;
}
.mat-body p,
.mat-body-1 p,
.mat-typography p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

label {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: #383838;
  margin-right: 20px;
}

span {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #383838;
}

input {
  height: 33px;
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
}

input[type='text'],
input[type='date'] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

body {
  margin: 0;
}

select {
  height: 33px;
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

button {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

button:disabled {
  height: 45px;
  background: #f3f3f3;
  border: 2px solid #383838;
  border-radius: 5px;
  color: #383838;
}

button:disabled:hover {
  height: 45px;
  background: #f3f3f3;
  border: 2px solid #383838;
  border-radius: 5px;
  color: #383838;
}

button:disabled > img {
  filter: invert(100%);
}

table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

th + th {
  border-left: 2px solid #fff;
}

tr + tr {
  border-bottom: 2px solid rgba(111, 111, 111, 0.2);
}

td + td {
  border-left: 2px solid rgba(111, 111, 111, 0.2);
}

th {
  padding-top: 17px;
  padding-left: 18px;
  height: 57px;
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #fff;
  background: #ff9d17;
}

td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 18px;
  height: 49px;
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  background: #fff;
}
.invalid {
  font-family: Banda Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #e94e1e;
}
.invalid-option {
  font-family: Banda Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 15px;
  text-align: left;
  color: #e94e1e;
}
.is-danger-select {
  border-color: #e94e1e !important;
}
.is-danger-select ~ ::before {
  border-color: #e94e1e !important;
}

.is-danger-input {
  border: 1px solid #e94e1e;
}
//select style
.box {
  position: relative;
  display: flex;
}

.box select {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background-color: #fff;
  border: 1px solid #777677;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.box::before {
  content: url('./assets/icons/lista-arrow-down.svg');
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  background-color: #ebeded;
  border-right: 1px solid #777677;
  border-top: 1px solid #777677;
  border-bottom: 1px solid #777677;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  padding-top: 4px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
//input date Style
::-webkit-datetime-edit-month-field {
  text-transform: uppercase;
}

::-webkit-datetime-edit-day-field {
  text-transform: uppercase;
}

::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}

.box-date {
  position: relative;
  display: flex;
}

.box-date input {
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background-color: #fff;
  border: 1px solid #777677;
  box-sizing: border-box;
}

.box-date::before {
  content: url('./assets/icons/vector-calendar-white.svg');
  top: 0;
  right: 0;
  width: 33px;
  height: 100%;
  background-color: #ff9d17;
  border-right: 1px solid #777677;
  border-top: 1px solid #777677;
  border-bottom: 1px solid #777677;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  padding-top: 4px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

input [type='date']::-webkit-inner-spin-button,
input [type='date']::-webkit-calendar-picker-indicator {
  width: 26px;
  height: 30px;
}

.text-title {
  font-family: 'Banda Bold' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: center !important;
  color: #4c7235 !important;
}

.text-parrafo {
  font-family: 'Banda Regular' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #4c7235 !important;
}

.box-date:hover::before {
  background-color: #777677;
}

.background {
  background-color: #f3f3f3 !important;
}
//titulos
.uppercase {
  text-transform: uppercase;
}
.title {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #d8241a;
  margin-top: 2% !important;
  margin-block-end: 0;
}

.title-second {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 26px;
  color: #d8241a;
  margin-block-end: 0;
  margin-block-start: 0;
  text-transform: lowercase !important;
}
.title-second::first-letter {
  text-transform: uppercase !important;
}

.title-modal {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #4c7235;
  margin-block-end: 0;
}

.subtitle {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.subtitle-card {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #777677;
}

.subtitle-modal {
  font-family: 'Banda Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #4c7235;
  margin-block-end: 0;
}

.text-success {
  font-family: Banda Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #a6b343;
}

.text-info {
  font-family: Banda Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #4169E1;
}

.text-begin {
  font-family: 'Banda Bold';
  font-size: 18px;
  line-height: 22px;
  text-align: end;
  color: #383838;
}

.text-begin-second {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #6f6d6d;
}

.text-li {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  color: #bfbfbf;
}
//botones
.button-period {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 0 5px 5px 0;
  color: #fff;
}

.button-period > img {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-back {
  height: 45px;
  background: #f3f3f3;
  border: 2px solid #383838;
  border-radius: 5px;
  color: #383838;
}

.button-back:hover {
  border: 2px solid #222 !important;
  border-radius: 5px !important;
  background-color: #000 !important;
  color: #fff !important;
  cursor: pointer !important;
}

.button-back:hover > img {
  filter: invert(100%);
}

.button-back > img {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.button-continue {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-continue:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-continue > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-apply {
  font-family: 'Banda Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 5px;
  height: 37px;
  width: 315px;
}

.button-apply :hover {
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  font-family: 'Banda Regular';
  font-size: 18px;
  cursor: pointer !important;
}

.button-close2 {
  margin-left: 96% !important;
  background-color: #d1d93e !important;
  background-image: url('assets/images/close.png') !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  background-position: center;
  width: 35px !important;
  height: 35px !important;
}

.button-close2:hover {
  margin-left: 96% !important;
  background-color: #4c7235 !important;
  background-image: url('assets/images/close_hover.png') !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  background-position: center;
  width: 35px !important;
  height: 35px !important;
}

.button-ver-cita {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-ver-cita:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-modify {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-modify:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-modify > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-cancel {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-cancel:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-cancel > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}
//Botones Modal
.button-try-again-modal {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-try-again-modal:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-try-again-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-no-modal {
  height: 45px;
  background: #f3f3f3;
  border: 2px solid #383838;
  border-radius: 5px;
  color: #383838;
}

.button-no-modal:hover {
  border: 2px solid #222 !important;
  border-radius: 5px !important;
  background-color: #383838;
  color: #fff;
  cursor: pointer !important;
}

.button-no-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-modify-modal {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-modify-modal:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-modify-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-cancel-modal {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-cancel-modal:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-cancel-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-end-modal {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-end-modal:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-end-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.button-close-modal {
  height: 45px;
  background: #e94e1e;
  border: 2px solid #e94e1e;
  border-radius: 5px;
  color: #fff;
}

.button-close-modal:hover {
  background-color: #ff9d17;
  border: 2px solid #ff9d17;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
}

.button-close-modal > img {
  position: relative;
  top: 2px;
  margin-left: 6px;
}
//botone Card
.card {
  background: #fff;
  border-radius: 10px;
  border: 2px solid #e8e8e8;
  filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.25));
  text-align: center;
  cursor: pointer;
}

.card:hover {
  border: 4px solid #4c7235;
  border-radius: 10px;
}
//.container-end{
//padding-bottom: 60px;
//}
//xl
@media screen and (min-width: 1920px) and (max-width: 4999.99px) {
  .card {
    height: 220px;
  }

  .container-title {
    width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-subtitle {
    width: 58%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-banner {
    width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-row {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    width: 1500px;
    height: auto;
    margin: 2% auto 10%;
    padding: 1% 0 0% 1%;
    padding-bottom: 60px;
    border-radius: 10px;
    background: #f3f3f3;
  }
  /* seccion step o wizard */
  .wizard {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 55%;
  }

  .wizard-box {
    width: 196px;
    height: 40px;
    background: #f2f2f2;
    border: 2px solid #bfbfbf;
    border-radius: 2px;
  }

  .done {
    border: 2px solid #a6b343 !important;
  }

  .done > .text-li {
    color: #a6b343 !important;
  }

  .done > .step {
    color: #fff !important;
    background: #a6b343 !important;
  }

  .active {
    border: 2px solid #f69220 !important;
  }

  .active > .text-li {
    color: #f69220 !important;
  }

  .active > .step {
    color: #fff !important;
    background: #f69220 !important;
  }

  .step {
    height: 101%;
    background: #bfbfbf;
    font-family: 'Banda Bold';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #8f8f8f;
  }
}
//lg
@media screen and (min-width: 1280px) and (max-width: 1919.9px) {
  .card {
    height: 220px;
  }

  .container-title {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-subtitle {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-banner {
    margin: auto;
    width: 1024px;
  }

  .container-row {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    width: 1024px;
    height: auto;
    margin: 2% auto 10%;
    padding: 1% 0 0%;
    padding-bottom: 60px;
    border-radius: 10px;
    background: #f3f3f3;
  }
  /* seccion step o wizard */
  .wizard {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 86%;
  }

  .wizard-box {
    width: 196px;
    height: 43px;
    background: #f2f2f2;
    border: 2px solid #bfbfbf;
    border-radius: 2px;
  }

  .done {
    border: 2px solid #a6b343 !important;
  }

  .done > .text-li {
    color: #a6b343 !important;
  }

  .done > .step {
    color: #fff;
    background: #a6b343 !important;
  }

  .active {
    border: 2px solid #f69220 !important;
  }

  .active > .text-li {
    color: #f69220 !important;
  }

  .active > .step {
    color: #fff !important;
    background: #f69220 !important;
  }

  .step {
    height: 101%;
    background: #bfbfbf;
    font-family: 'Banda Bold';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #8f8f8f;
  }
}
//md
@media screen and (min-width: 960px) and (max-width: 1279.9px) {
  .card {
    height: 220px;
  }

  .container-title {
    width: 780px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-subtitle {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-banner {
    margin: auto;
    width: 780px;
  }

  .container-row {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    width: 780px;
    height: auto;
    margin: 2% auto 15%;
    padding: 1% 0 0% 0%;
    padding-bottom: 60px;
    border-radius: 10px;
    background: #f3f3f3;
  }
  /* seccion step o wizard */
  .wizard {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 90%;
  }

  .wizard-box {
    width: 165px;
    height: 44px;
    background: #f2f2f2;
    border: 2px solid #bfbfbf;
    border-radius: 2px;
  }

  .done {
    border: 2px solid #a6b343 !important;
  }

  .done > .text-li {
    color: #a6b343 !important;
  }

  .done > .step {
    color: #fff !important;
    background: #a6b343 !important;
  }

  .active {
    border: 2px solid #f69220 !important;
  }

  .active > .text-li {
    color: #f69220 !important;
  }

  .active > .step {
    color: #fff !important;
    background: #f69220 !important;
  }

  .step {
    height: 101%;
    background: #bfbfbf !important;
    font-family: 'Banda Bold';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #8f8f8f;
  }
}
//sm
@media screen and (min-width: 600px) and (max-width: 959.9px) {
  .text-li {
    margin-left: 5px;
  }

  .container-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-banner {
    margin: auto;
    width: 90%;
  }

  .container {
    width: 90%;
    height: auto;
    margin: 2% auto 20%;
    padding: 1% 0 0% 0%;
    padding-bottom: 60px;
    background: #f3f3f3;
  }

  .card :hover {
    border: 1px solid #4c7235 !important;
  }

  .card {
    background: #fff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .wizard {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    width: 90%;
  }

  .wizard-box {
    width: 145px;
    height: 50px;
    background: #f2f2f2;
    border: 2px solid #bfbfbf;
    border-radius: 2px;
  }

  .done {
    border: 2px solid #a6b343 !important;
  }

  .done > .text-li {
    color: #a6b343;
  }

  .done > .step {
    color: #fff;
    background: #a6b343;
  }

  .active {
    border: 2px solid #f69220 !important;
  }

  .active > .text-li {
    color: #f69220;
  }

  .active > .step {
    color: #fff;
    background: #f69220;
  }

  .step {
    height: 101%;
    background: #bfbfbf;
    font-family: 'Banda Bold';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #8f8f8f;
  }

  input,
  textarea {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }
}
//xs
@media screen and (max-width: 599.9px) {
  body {
    background-color: #f3f3f3;
  }

  .button-back {
    touch-action: pan-right pinch-zoom;
    width: 158px;
  }

  .button-continue {
    touch-action: pan-right pinch-zoom;
    width: 158px;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
  }

  .title-second {
    font-size: 14px;
    line-height: 18px;
  }

  .container-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-mobil {
    height: 100vh;
  }

  .container {
    width: 100%;
    height: auto;
    margin-bottom: 60%;
  }

  .container-banner {
    background: #fff;
  }

  .card :hover {
    border: 1px solid #4c7235 !important;
  }

  .card {
    background: #fff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 5px;
    filter: none;
  }

  select {
    height: 33.04px;
    font-family: 'Banda Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 23px;
  }
  /* seccion step o wizard */
  .wizard-box {
    height: 50px;
    z-index: 20;
  }

  .wizard-box > .step {
    width: 25px;
    height: 25px;
    padding-top: 1px;
    background: #f2f2f2;
    border-radius: 50%;
    font-family: 'Banda Bold';
    line-height: 23px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    color: #8f8f8f;
    z-index: 20;
  }

  .text-xs {
    width: 80px;
    margin-top: 5px;
    font-family: 'Banda bold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    z-index: 20;
  }

  .done > .text-xs {
    color: #a6b343;
  }

  .done > .step {
    background: #a6b343;
  }

  .done > .step {
    color: #fff;
  }

  .active > .text-xs {
    color: #f69220;
  }

  .active > .step {
    background: #f69220;
  }

  .active > .step {
    color: #fff;
  }

  input,
  textarea {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }

  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
//Tipos Letras
@font-face {
  font-family: 'Banda Bold';
  src: url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.eot'); /* IE9*/
  src: url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.woff2') format('woff2'),
    url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.woff') format('woff'),
    url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.ttf') format('truetype'),
    url('./assets/fonts/BandaBold/3e8415fa8c116a5142e8097ffc0ad011.svg#Banda Bold') format('svg'); /* iOS 4.1- */
}
@font-face {
  font-family: 'Banda Regular';
  src: url('./assets/fonts/BandaRegular/regular.otf');
}
.mat-body p,
.mat-body-1 p,
.mat-typography p {
  display: block;
  -webkit-margin-before: 2px;
  margin-block-start: 2px;
  -webkit-margin-after: 2px;
  margin-block-end: 2px;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
}
